import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal, Collapse } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { userContext } from "../contexts/UserContext";
import { PrivacyPolicyText } from "../components/PrivacyPolicyText";
import { Bar } from "react-chartjs-2";
import { FaAngleDown } from 'react-icons/fa';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from '../components/PDFDocument';

export const PersonalizationPage: React.FC = () => {
	const context = useContext(userContext);
	const history = useHistory();
	const [hexadQuestions, setHexadQuestions] = useState<any[]>([]);
	const [formStep, setFormStep] = useState(0);
	const [chunkStep, setChunkStep] = useState(0);
	const [inChunkAnswered, setInChunkAnswered] = useState(0);
	const [playerTypeInfo, setPlayerTypeInfo] = useState<any[]>([]);
	const [mainPlayerTypesTitle, setMainPlayerTypesTitle] = useState([]);
	const [showError, setShowError] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
	const [playerTypesLabel, setPlayerTypesLabel] = useState<any[]>([]);
	const [playerTypesPercentage, setPlayerTypesPercentage] = useState<any[]>([]);
	const [maxPercentage, setMaxPercentage] = useState(0);
	const [showProgressbarSuccess, setShowProgressbarSuccess] = useState(false);
	const [showProgressbar, setShowProgressbar] = useState(false);
	const [userId, setUserId] = useState('');
	const [userType, setUserType] = useState('');
	const [age, setAge] = useState('');
	const [semester, setSemester] = useState('');
	const [gender, setGender] = useState('');

	const sliceIntoChunks = (arr: any[], chunkSize: number) => {
		const res = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			const chunk = arr.slice(i, i + chunkSize);
			res.push(chunk);
		}
		return res;
	}

	type genderT = {
		name: string;
		value: string;
	};

	const genderRadios: Array<genderT> = [
		{name: "Weiblich", value: 'w'},
		{name: "Männlich", value: 'm'},
		{name: "Divers", value: 'd'},
	];

	// get personalization data from backend
	const getPersonalization = () => {
		setLoading(true);
		context.actions
			.getPersonalization()
			.then((response: any) => {
				if (response.status === 200) {
					let data = response.data;
					if (data.status === 'success') {
						history.push("/");
					} else if (data.status === 'initial') {
						let {hexad, public_user_id} = data.data;

						hexad = hexad.map((element: any) => {
							return {
								...element,
								score: 0,
								init: true,
								error: false,
							}
						});

						setHexadQuestions(hexad);
						setUserId(public_user_id);
					}
				}
			})
			.catch((error: any) => {
				console.error(error);
			}).finally(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		getPersonalization();
	}, []);

	const setPersonalization = () => {
		const hexadPayload = hexadQuestions.map((element: any) => {
			return {
				id: Number(element.id),
				score: Number(element.score),
			}
		});

		setLoading(true);
		context.actions
			.setPersonalization({
				hexad: hexadPayload,
				user_type: userType,
				gender: gender,
				age: Number(age),
				semester: Number(semester),
			},userId)
			.then((response: any) => {
				if (response.status === 200) {
					let data = response.data;

					if (data.status === 'success') {
						const playerTypes = data.data;
						const playerMainTypesTitle = playerTypes.filter((playerType: any) => playerType.isMax).map((element: any) => {
							return element.title;
						});

						setMainPlayerTypesTitle(playerMainTypesTitle);

						const playerTypeInfo = playerTypes.map(({title, description, percentage}: any) => {
							return {
								title,
								description,
								percentage,
								open: false,
							}
						});

						setPlayerTypeInfo(playerTypeInfo);

						const playerTypesTitles = playerTypes.map((element: any) => element.title);
						setPlayerTypesLabel(playerTypesTitles);
						console.log("playerTypesTitles: " + playerTypesTitles);

						setPlayerTypesPercentage(playerTypes.map((element: any) => {
							const percentage = Math.round(element.percentage * 100);

							if (percentage > maxPercentage) {
								setMaxPercentage(percentage);
							}

							return percentage;
						}));

						window.scrollTo(0, 0);
						setFormStep(2);
					}
				}
			})
			.catch((error: any) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleHexadChange = (event: any, index: number) => {
		let newHexadQuestions = [...hexadQuestions];

		if (newHexadQuestions[index].init) {
			setInChunkAnswered(inChunkAnswered + 1);
		}

		newHexadQuestions[index].score = event.target.value;
		newHexadQuestions[index].init = false;
		newHexadQuestions[index].error = false;
		setHexadQuestions(newHexadQuestions);
	};


	const setOpen = (title: string, open: boolean) => {
		const newPlayerTypeInfo = [...playerTypeInfo];

		const index = newPlayerTypeInfo.findIndex((element: any) => element.title === title);
		newPlayerTypeInfo[index].open = open;

		setPlayerTypeInfo(newPlayerTypeInfo);
	}

	function CollapsibleItem(title: string, description: string, percentage: number, open: boolean) {
		return (
			<div className="card mb-3 justify-content-between">
				<div className="card-header d-flex justify-content-between align-items-center w-100"
					 style={{cursor: 'pointer'}} onClick={() => setOpen(title, !open)}>
					<h5 className="card-title mb-0">{title}: {Math.round(percentage * 100)}%</h5>
					<FaAngleDown className={`faq-icon ${open ? 'open' : ''}`} size={20}/>
				</div>
				<Collapse in={open} role="button">
					<div className="card-body" id={`${title}-collapse-text`}>
						<p>{description}</p>
					</div>
				</Collapse>
			</div>
		);
	}

	const renderTooltip = (props: any, description: string) => (
		<Tooltip id="button-tooltip" {...props}>
			{description}
		</Tooltip>
	);

	const progressBar = () => {
		let width = 0;
		if (formStep === 1) {
			width = (chunkStep + 1) * 25;
		} else if (formStep === 3) {
			width = (chunkStep + 1) * 11.11;
		}

		if (width === 0) {
			return null;
		}

		// if progress add additional class to make it green
		let progressbarClasses = "progress-bar progress-bar-new";
		if (showProgressbarSuccess) {
			progressbarClasses = progressbarClasses.concat(" progress-bar-new--success");
			width = 100;
		}

		return (
			<div className="progress" style={{height: '1rem'}}>
				<div className={progressbarClasses} role="progressbar" style={{width: `${width}%`}}
					 aria-valuenow={(chunkStep + 1) * 25} aria-valuemin={0} aria-valuemax={100}></div>
			</div>
		);
	}


	return (
		<div>
			<div className="d-flex align-items-center customHeight personalization-container mx-auto">
				{isLoading ? (
					<div className="container align-items-center d-flex justify-content-center">
                    <span
						className="spinner-border spinner-border-sm ml-5"
						role="status"
						aria-hidden="true"
					/>
					</div>
				) : (
					<div className="container align-items-center box-blur" style={{
						marginTop: '3rem',
						marginBottom: '3rem',
						borderRadius: '20px',
						paddingTop: '20px',
						paddingBottom: '20px',
						color: '#000'
					}}>
						<Form style={{padding: '20px'}}>
							{progressBar()}
							{formStep === 0 ? (
								<div>
									<h3 className="text-center" style={{paddingBottom: '1.5rem'}}>
										Bestimme deinen Spielertypen
									</h3>
									<div key={'userType'}>
										<Form.Label className="font-weight-bold">Bitte geben Sie Ihre Berufsbezeichnung
											an:</Form.Label>
										<Form.Control
											type="text"
											placeholder="Berufsbezeichnung eingeben"
											value={userType}
											onChange={(e) => setUserType(e.target.value)}
											className="mt-1"
										/>
										<div className="text-center" style={{
											color: 'red',
											marginBottom: '1rem',
											visibility: (showError && userType === '' ? 'visible' : 'hidden')
										}}>
											Bitte geben Sie eine Berufsbezeichnung ein.
										</div>
									</div>
									<Form.Group>
										<Form.Label className="font-weight-bold">Geschlecht</Form.Label>
										<ButtonGroup className="mt-1 justify-content-center d-flex btn-group-new">
											{genderRadios.map((radio, idx) => (
												<Button
													key={idx}
													name="genderId"
													variant={gender === radio.value ? 'primary' : 'light'}
													value={radio.value}
													onClick={() => {
														setGender(radio.value);
													}}
												>
													{radio.name}
												</Button>
											))}
										</ButtonGroup>
										<div className="text-center" style={{
											color: 'red',
											marginBottom: '1rem',
											visibility: (showError && gender === '' ? 'visible' : 'hidden')
										}}>
											Bitte wähle einen Wert für diese Frage aus.
										</div>
									</Form.Group>
									<Form.Group>
										<Form.Label className="font-weight-bold">Alter</Form.Label>
										<Form.Control
											type="number"
											name="age"
											placeholder="Alter eingeben"
											min="14"
											max="110"
											value={age}
											onChange={(e: any) => setAge(e.currentTarget.value)}
										/>
										<div className="text-center" style={{
											color: 'red',
											marginBottom: '1rem',
											visibility: (showError && age === '' ? 'visible' : 'hidden')
										}}>
											Bitte gebe dein Alter an.
										</div>
									</Form.Group>
									<p className="text-center">
										Anhand der nachfolgenden Fragen bestimmen wir deinen Spielertypen. Auf Basis
										dieser Informationen können wir dir eine personalisierte Lernerfahrung auf
										unserer Plattform bieten.
									</p>
									<p className="text-center" style={{paddingBottom: '1rem'}}>
										-3 bedeutet, dass du überhaupt nicht zustimmst und +3 bedeutet, dass du voll und
										ganz zustimmst.
									</p>

									<div className="d-flex justify-content-center">
										<Button
											variant="primary"
											style={{width: '100%'}}
											className="mb-1 button-new"
											onClick={() => {
												// check if form is valid
												if (userType === '' || gender === '' || age === '' || (userType === 'student' && semester === '')) {
													setShowError(true);
													return;
												} else {
													setShowError(false);
												}

												if (Number(age) <= 0 || Number(age) > 110) {
													setAge('');
													setShowError(true);
													return;
												}

												if (userType === 'student' && (Number(semester) <= 0 || Number(semester) > 30)) {
													setSemester('');
													setShowError(true);
													return;
												}

												window.scrollTo(0, 0);
												setFormStep(1);
											}}
										>
											Start
										</Button>
									</div>
								</div>
							) : null}
							{formStep === 1 ? (
								<div>
									<Form.Group style={{marginTop: '1rem'}}>
										{sliceIntoChunks(hexadQuestions, 4).map((chunk, chunkIndex) => {
											if (chunkStep !== chunkIndex) {
												return null;
											}

											return (
												<div key={chunkIndex}>
													{chunk.map((question, index) => {
														return (
															<div key={index}>
																<Form.Label style={{
																	fontWeight: 'bold',
																	marginBottom: '1rem',
																	marginTop: '0.5rem',
																	display: 'block'
																}} className="text-center">
																	{question.title}
																</Form.Label>
																<ButtonGroup aria-label={question.title}
																			 className="mt-1 justify-content-center d-flex btn-group-new">
																	{[-3, -2, -1, 0, 1, 2, 3].map(value => (
																		<Button
																			key={value}
																			variant={value === question.score && !question.init ? 'primary' : 'light'}
																			onClick={() => handleHexadChange({target: {value}}, chunkIndex * 4 + index)}
																		>
																			{value} {question.init}
																		</Button>
																	))}
																</ButtonGroup>
																<div className="text-center" style={{
																	color: 'red',
																	marginBottom: '1rem',
																	visibility: (question.error ? 'visible' : 'hidden')
																}}>
																	Bitte wähle einen Wert für diese Frage aus.
																</div>
															</div>
														);
													})}
													<Button
														variant="primary"
														style={{width: '100%'}}
														className="mb-1 mt-3 button-new"
														disabled={inChunkAnswered !== 4}
														onClick={() => {
															//window.scrollTo(0, 0);

															let newHexadQuestions = [...hexadQuestions];
															let error = false;
															newHexadQuestions.forEach((question, index) => {
																if (question.init && index >= chunkStep * 4 && index < (chunkStep + 1) * 4) {
																	newHexadQuestions[index].error = true;
																	error = true;
																}
															});
															setHexadQuestions(newHexadQuestions);

															if (!error) {
																setShowError(false);
															} else {
																setShowError(true);
																return;
															}

															setInChunkAnswered(0);

															if (chunkIndex === sliceIntoChunks(hexadQuestions, 4).length - 1) {
																setShowProgressbarSuccess(true);

																setTimeout(() => {
																	setPersonalization();
																	setFormStep(2);
																	setChunkStep(0);
																	setShowProgressbarSuccess(false);
																}, 1000);
															} else {
																setChunkStep(chunkIndex + 1);
															}
														}}
													>
														Weiter
													</Button>
												</div>
											)
										})}
									</Form.Group>
								</div>
							) : null}
							{formStep === 2 ? (
								<div>
									<h3 className="text-center" style={{paddingBottom: '2rem'}}>
										Dein Spielertyp lautet:{'\n'}<br/>
										<span
											style={{fontWeight: 700}}>{mainPlayerTypesTitle.length === 1 ? mainPlayerTypesTitle[0] : mainPlayerTypesTitle.join(" & ")}</span>
									</h3>

									<Bar
										data={{
											labels: playerTypesLabel,
											datasets: [
												{
													label: '% Spielertyp',
													data: playerTypesPercentage,
													backgroundColor: 'rgba(133, 227, 253, 0.2)',
													borderColor: 'rgba(133, 227, 253, 1)',
													borderWidth: 1,
												},
											],
										}}
										options={{
											scales: {
												y: {
													beginAtZero: true,
												},
											},
										}}
									/>
									<h4 className="mb-4 text-center" style={{marginTop: '3rem'}}>Mehr über deine
										Spielertypen erfahren:</h4>
									{(
										playerTypeInfo.map(({title, description, percentage, open}, index) => {
											return (CollapsibleItem(title, description, percentage, open))
										})
									)}
									<PDFDownloadLink
										document={
											<PDFDocument
												mainPlayerTypesTitle={mainPlayerTypesTitle}
												playerTypesLabel={playerTypesLabel}
												playerTypesPercentage={playerTypesPercentage}
												playerTypeInfo={playerTypeInfo}
											/>
										}
										fileName="spielertyp-auswertung.pdf"
									>
										{({blob, url, loading, error}) =>
											loading ? 'Lade Dokument...' : 'Auswertung als PDF herunterladen'
										}
									</PDFDownloadLink>
									<Button
										variant="primary"
										style={{width: "100%"}}
										className="mb-1 button-new"
										onClick={() => {
											window.location.reload();

										}}
									>
										Erneut starten
									</Button>
								</div>) : null}
						</Form>
					</div>
				)}
			</div>


			<footer className="text-muted bg-gradient footer">
				<div
					className="container d-flex justify-content-between"
					style={{paddingBottom: "10px", paddingTop: "10px"}}
				>
					<div>
						<a href="https://fbi.h-da.de/" className="text-white">Hochschule Darmstadt Fachbereich
							Informatik</a>
					</div>
					<div className="privacyPolicyText text-white" onClick={() => setPrivacyModalIsOpen(true)}>
						Datenschutzerklärung
					</div>
				</div>
			</footer>

			<Modal
				size="lg"
				show={privacyModalIsOpen}
				onHide={() => setPrivacyModalIsOpen(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Datenschutzerklärung
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrivacyPolicyText/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setPrivacyModalIsOpen(false)}>
						Schließen
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};