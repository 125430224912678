import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {Button, Container, Tooltip, OverlayTrigger} from "react-bootstrap";
import axios from "axios";
import {AppSettings} from "../config";
import {lernSessionContext} from "../contexts/LernSessionContext";
import {userContext} from "../contexts/UserContext";

type InterviewProp = {
    title : string,
    textOfFirstRow : string,
    textOfSecondRow: string,
    textOfThirdRow: string,
    textOfFourthRow: string,
    category_id : string,
    categoryStatus : string,
    icon_path : string,
}
export const InterviewBackground = ( interviewProp : InterviewProp) => {
    const history = useHistory();
    const context = useContext(userContext);
    const lernSession = useContext(lernSessionContext);

    const handleButton = () => {
        history.push("/abschlusstest/" + interviewProp.category_id);
    };

    async function createAbschlussTest(){
        try {
            if (lernSession) {

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: context.actions.authHeader(),
                    },
                };

                const postData = {
                    user_id: context.user?.user_id,
                    category_id: Number(interviewProp.category_id),
                    lernsession_id: lernSession.lernSessionId,
                };

                await axios.post(AppSettings.CREATE_ABSCHLUSSTEST, postData, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("response: ", response);

                            history.push('/abschlusstest/' + interviewProp.category_id + '/' + response.data.data.abschlusstest_id);

                        }
                    })
                    .catch((error) => {
                        console.error("Error creating lernsession:", error);
                        // Appropriately handle the error
                        console.log("this happens when i want to create a lernsession" + context.user?.user_id);
                        console.log(error.message);
                        console.log(error.request);
                        console.log(error.config);
                    });
            }
        } catch (error) {
            console.error("abschlusstest_geschrieben couldn't be updated", error);
        }
    }

    return (
        <Container>
            <section className="jumbotron text-center" style={{paddingBottom: "5rem"}}>
                <div className="container" key={"process_procedure_video"}>
                    <h1 className="jumbotron-heading">{interviewProp.title}</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>
                                <div className="col s4">
                                    <img
                                        src={interviewProp.icon_path}
                                        alt=""
                                    />
                                </div>
                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text">{interviewProp.textOfFirstRow}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className={"col s12"}>*/}
                    {/*        <div className={"row"}>*/}
                    {/*            <div className="col s8 my-auto" style={{textAlign: "left"}}>*/}
                    {/*                <p className="header-topic-text"> {interviewProp.textOfSecondRow}*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*            <div className="col s4">*/}
                    {/*                <img*/}
                    {/*                    src={"/images/befragter/start.png"}*/}
                    {/*                    alt=""*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>

                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text">{interviewProp.textOfThirdRow}
                                    </p>
                                </div>
                                <div className="col s4">
                                    <img
                                        src={"/images/befragter/dialog_icon.png"}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>
                                <div className="col s4">
                                    <img
                                        src={"/images/befragter/done_green.png"}
                                        alt=""
                                    />
                                </div>
                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text"> {interviewProp.textOfFourthRow}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <OverlayTrigger
                    placement="bottom"
                    delay={{show: 250, hide: 400}}
                    overlay={
                        interviewProp.categoryStatus === "3" ? (
                            <Tooltip id={`tooltip`}>Abschlusstest bereits bestanden!</Tooltip>
                        ) : (
                            <div />
                        )
                    }
                >
                    <Button style={{float: "left", marginLeft: "13px"}} onClick={() => createAbschlussTest()} disabled={interviewProp.categoryStatus === "3"}>Zum Abschlusstest</Button>
                </OverlayTrigger>
            </section>
        </Container>


    );
}