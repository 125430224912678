import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import * as React from "react";
import {InterviewBackground} from "../components/InterviewBackground";
import {userContext} from "../contexts/UserContext";
import { lernSessionContext } from "../contexts/LernSessionContext";
import watsonTrainingIntegrationIDsJson from '../components/watson_training.json'

import {UserStatus} from "../models/User";
import axios from "axios";
import {AppSettings} from "../config";
import chatInputMonitor from "../components/ChatInputMonitor";
import ChatInputMonitor from "../components/ChatInputMonitor";


//TODO: lernsession_id rausnehmen
type TParams = { id: string, lernsessionId: string};

declare global {
    interface Window {
        watsonAssistantChatOptions: any;
    }
}

export const FreeAnswerInterviewPage = ({ match }: RouteComponentProps<TParams>) => {
    const history = useHistory();
    const location = useLocation();

    const [category_id] = useState(match.params.id);

    const context = useContext(userContext);
    const lernSession = useContext(lernSessionContext);

    const [userStatus] = useState<UserStatus[]>(context.status);
    let categoryStatus: string = "";

    userStatus.forEach((entry) => {
        if (Number(category_id) === entry.category.category_id) {
            categoryStatus = entry.status;
        }
    });

    useEffect(() => {
        const customLanguagePack = {
            "closeAndRestartModal_title": "Chat-Verlauf löschen",
            "closeAndRestartModal_message": "Bist du sicher, dass du den Chat-Verlauf löschen willst?",
            "closeAndRestartModal_confirm": "Ja",
            "closeAndRestartModal_cancel": "Nein",
        };

        const watsonTrainingIDs = JSON.parse(JSON.stringify(watsonTrainingIntegrationIDsJson));
        let watsonTrainingID = ""

        if (category_id in watsonTrainingIDs) {
            watsonTrainingID = watsonTrainingIDs[category_id]

        window.watsonAssistantChatOptions = {
            integrationID: watsonTrainingID, // The ID of this integration.
            region: "eu-de", // The region your integration is hosted in.
            serviceInstanceID: "f450ea51-1230-4fcf-af43-652cab013db7", // The ID of your service instance.
            showCloseAndRestartButton: true,
            onLoad: function (instance: any) {
                instance.updateUserID(String(context.user?.user_id));
                instance.render();
                instance.updateLanguagePack(customLanguagePack);
            }
        };
        setTimeout(function () {
            const t = document.createElement('script');
            t.src = "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js";
            document.head.appendChild(t);
        });
    }
        else{
            console.log('Für dieses Fachthema ist noch kein Chatbot hinterlegt!')
        }
    }, [])

    function updateLernSessionFinishedAt(){
        try {
            if (lernSession) {

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: context.actions.authHeader(),
                    },
                };

                axios.put(AppSettings.UPDATE_LERNSESSION_FINISHEDAT + lernSession.lernSessionId, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("response: ", response);
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating lernsession:", error);
                        // Appropriately handle the error
                        console.log(error.message);
                        console.log(error.request);
                        console.log(error.config);
                    });
            }
        } catch (error) {
            console.error("abschlusstest_geschrieben couldn't be updated", error);
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            console.log("actual path", location.pathname);
            if (location.pathname !== `/abschlusstest/${category_id}`) {
                updateLernSessionFinishedAt();
            }
        });
    }, [history]);



    return (
        <>
            <ChatInputMonitor />
        <InterviewBackground title={"Trainingsdialog mit freien Antworten"}
                             textOfFirstRow={"Mit einem Klick auf das Icon unten rechts gelangst du zum Chatbot. " +
             "Mit diesem kannst du die Trainingseinheit in einem Schwierigkeitsgrad deiner Wahl starten."}
            textOfSecondRow={"Um den Trainingsdialog zu starten, muss ein Schwierigkeitsgrad ausgewählt werden."}
                             textOfThirdRow={"Der nächste Schritt ist die Beantwortung von Fragen, die dir der Chatbot stellt."}
                             textOfFourthRow={"Wenn du alle Trainingseinheiten erfolgreich absolviert hast, bist du bereit, " +
                                 "das Thema abzuschließen. Gehe dazu zum Abschlusstest und beantworte die Fragen."}
                             category_id={category_id}
                             categoryStatus={categoryStatus}
                             icon_path={"/images/befragter/watson_icon.png"} />
        </>
    );
}