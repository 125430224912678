// PDFDocument.tsx
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 15,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 12,
        borderTopWidth: 1,
        borderTopColor: '#000',
        paddingTop: 10,
    },
    logo: {
        width: 150,
        height: 'auto',
    },
    link: {
        color: 'black',
        textDecoration: 'underline',
        fontSize: 12,
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 5,
        textAlign: 'center',
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
    section: {
        marginBottom: 10,
    },
    playerTypeContainer: {
        padding: 10,
        marginBottom: 10,
        border: '1px solid #eaeaea',
        borderRadius: 5,
        backgroundColor: '#f9f9f9',
    },
    playerTypeTitle: {
        fontSize: 14,
        marginBottom: 5,
        fontWeight: 'bold',
    },
    playerTypeDescription: {
        fontSize: 12,
        marginBottom: 5,
    },
    playerTypePercentage: {
        fontSize: 12,
        fontStyle: 'italic',
    },
});

interface PDFDocumentProps {
    mainPlayerTypesTitle: string[];
    playerTypesLabel: string[];
    playerTypesPercentage: number[];
    playerTypeInfo: {
        title: string;
        description: string;
        percentage: number;
    }[];
}

const PDFDocument: React.FC<PDFDocumentProps> = ({ mainPlayerTypesTitle, playerTypesLabel, playerTypesPercentage, playerTypeInfo }) => (
    <Document>
        <Page style={styles.page}>
            <View style={styles.header}>
                <Image
                    style={styles.logo}
                    src="/securebot_eut.png"
                />
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>
                    Dein Spielertyp lautet: {'\n'}
                    {mainPlayerTypesTitle.length === 1 ? mainPlayerTypesTitle[0] : mainPlayerTypesTitle.join(" & ")}
                </Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.subtitle}>Spielertyp Beschreibungen:</Text>
                {playerTypeInfo.map((type, index) => (
                    <View key={index} style={styles.playerTypeContainer}>
                        <Text style={styles.playerTypeTitle}>{type.title} {(type.percentage *100).toFixed(0)}%</Text>
                        <Text style={styles.playerTypeDescription}>{type.description}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.footer}>
                <Link style={styles.link} src="https://www.securebot-project.de">
                    www.securebot-project.de
                </Link>
                <Link style={styles.link} src="mailto:securebot-contact.fbi@h-da.de">securebot-contact.fbi@h-da.de</Link>
            </View>
        </Page>
    </Document>
);

export default PDFDocument;