import axios from "axios";
import React, { useEffect, useState } from "react";
import {
	LoginOptions,
	LoginUser,
	CreateUserData,
	UserStatus,
	FilterOptions,
	SortOptions,
	PersonalizationOptions
} from "../models/User";
import { AppSettings } from "../config";
import { useHistory } from "react-router-dom";

type UserContext = {
	user: LoginUser | null;
	actions: {
		register: (registerOptions: CreateUserData) => any;
		login: (loginOptions: LoginOptions) => any;
		logout: () => void;
		getToken: () => string | null;
		authHeader: () => string | null;
		updateTimer: (timer:boolean) => any;
		getPersonalization: () => any;
		setPersonalization: (personalizationOptions: PersonalizationOptions, userId: string) => any;
	};
	status: UserStatus[];
	filterOptions: FilterOptions;
	sortOptions: SortOptions;
	timer: boolean;
};

export const initialContext = {
	user: null,
	actions: {
		register: (registerOptions: CreateUserData) => {},
		login: (loginOptions: LoginOptions) => {},
		logout: () => {},
		authHeader: () => {
			return null;
		},
		getToken: () => {
			return null;
		},
		updateTimer: (timer:boolean) => {},
		getPersonalization: () => {},
		setPersonalization: () => {},
	},
	status: [],
	filterOptions: {
		favorite: false,
		status: [true, true, true]
	},
	sortOptions: {
		favorite: false,
		alphabet: false,
		status: false,
	},
	timer: true,
};

export const userContext = React.createContext<UserContext>(initialContext);

export const UserProvider: React.FC = ({ children }) => {
	const remainingTime = 120;

	const history = useHistory();
	const [user, setUser] = useState<LoginUser | null>(null);
	const [userToken, setUserToken] = useState(null);
	const [loginExpiration, setLoginExpiration] = useState<string>();

	const [userStatus, setUserStatus] = useState<UserStatus[]>([]);
	const [filterOptions, setFilterOptions] = useState<FilterOptions>({
		favorite: false,
		status: [true, true, true]
	});
	const [sortOptions, setSortOptions] = useState<SortOptions>({
		favorite: false,
		alphabet: true,
		status: false,
	});
	let [timer, setTimer] = useState(true);

	const register = async ({
								nickname,
								password,
								e_mail,
								age,
								gender_id,
							}: CreateUserData) => {
		try {
			const response = await axios.post(AppSettings.AUTH_REGISTER_URL, {
				nickname,
				password,
				e_mail,
				age,
				gender_id,
			});

			if (response.status === 200) {
				const status = await axios.get(
					AppSettings.USER_STATUS + response.data.user_id
				);
				if (status.status === 200) {
					setUserStatus(status.data.data);
				}

				return response;
			} else {
				return response
			}
		} catch (e) {
			console.error(e);
		}
	};

	// const login = async ({ nickname, password }: LoginOptions) => {
	//   try {
	//     const res = await axios.post(AppSettings.AUTH_LOGIN_URL, {
	//       nickname,
	//       password,
	//   });
	//     if (res.status === 200) {
	//       const token = res.data?.data?.access_token;
	//       setUser(res.data.data);
	//       setUserToken(token);
	//
	//       const status = await axios.get(AppSettings.USER_STATUS + res.data.data.user_id);
	//       if(status.status === 200) {
	//         console.log("Status Response Login: ", status.data.data);
	//         setUserStatus(status.data.data);
	//         console.log("Status vom Login", userStatus);
	//         console.log("counter before: ", counter);
	//         setCounter(10);
	//         console.log("counter after: ", counter);
	//       }
	//       window.localStorage.setItem("access_token", token);
	//       return res;
	//     }
	//     return null; // status fälle muss abgeändert werden
	//   } catch (e) {
	//     console.error(e);
	//   }
	// };

	useEffect(() => {
		async function fetchData() {
			if (user) {
				await axios.get(AppSettings.USER_STATUS + user?.user_id)
					.then((response) => {
						if (response.status === 200) {
							setUserStatus(response.data.data);
						}
					})
					.catch((error) => {
						console.log("Log out");
					});
			}
		}
		//fetchData();
	}, [user?.user_id]);

	/*useEffect(() => {
		setTimeout(() => {
			logout();
			history.push("/login");
		}, remainingTime * 60 * 1000);
	});*/

	// useEffect(()=> {
	//   async function fetchData() {
	//     const result = await axios.get(AppSettings.USER_STATUS + user?.user_id);
	//     if(result.status === 200) {
	//       setUserStatus(result.data.data);
	//     }
	//   };
	//   fetchData();
	// }, [user?.user_id]);

	const login = async ({ nickname, password }: LoginOptions) => {
		try {
			const response = await axios.post(AppSettings.AUTH_LOGIN_URL, {
				nickname,
				password,
			});
			if (response.status === 200) {
				const token = response.data.access_token;
				const expire = response.data.expires_in;
				setUser(response.data);
				setUserToken(token);
				setLoginExpiration(expire);

				await axios
					.get(AppSettings.USER_STATUS + response.data.user_id)
					.then((response) => {
						if (response.status === 200) {
							setUserStatus(response.data.data);
						}
					});
				window.localStorage.setItem("access_token", token);
				window.localStorage.setItem("expires_in", expire);
				// window.localStorage.setItem("isLoggedIn", `${true}`);
				// window.localStorage.setItem("user_rights", res.data.data.user_rights_id);
				// window.localStorage.setItem("user_id", res.data.data.user_id);
				return response;
			} else {
				return response; // status fälle muss abgeändert werden
			}
		} catch (e) {
			console.error(e);
		}
	};

	function logout() {
		localStorage.removeItem("access_token");
		// localStorage.removeItem("isLoggedIn");
		// localStorage.removeItem("user_rights");
		// localStorage.removeItem("user");
		setUserToken(null);
		setUser(null);
	}

	function getToken() {
		return userToken;
	}

	function authHeader() {
		const access_token = getToken();

		if (access_token) {
			return "Bearer " + access_token;
		} else {
			return null;
		}
	}
	function updateTimer(timer:boolean) {
		setTimer(timer);
	}

	const getPersonalization = async () => {
		try {
			const res = await axios.get(AppSettings.PUBLIC_USER_PERSONALIZATION, {
				headers: {
					Authorization: authHeader(),
				},
			});

			return res;
		} catch (e) {
			console.error(e);
		}
	};

	const setPersonalization = async (personalization: PersonalizationOptions, userId: string) => {
		try {
			const res = await axios.post(
				AppSettings.PUBLIC_USER_PERSONALIZATION,
				{
					...personalization,
					public_user_id: userId,
				},
				{
					headers: {
						Authorization: authHeader(),
					},
				}
			);

			return res;
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<userContext.Provider
			value={{
				user: user,
				actions: { login, getToken, logout, register, authHeader, updateTimer, getPersonalization, setPersonalization },
				status: userStatus,
				filterOptions: filterOptions,
				sortOptions: sortOptions,
				timer: timer,
			}}
		>
			{children}
		</userContext.Provider>
	);
};
