import React, {useContext, useEffect, useRef, useState} from 'react';
import {lernSessionContext} from "../contexts/LernSessionContext";
import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {AppSettings} from "../config";
import {userContext} from "../contexts/UserContext";
const ChatInputMonitor: React.FC = () => {
    const [interactionCount, setInteractionCount] = useState(0);
    const [confirmUnload, setConfirmUnload] = useState(false);

    const lernSession = useContext(lernSessionContext);
    const location = useLocation();
    const history = useHistory();
    const context = useContext(userContext);


    function updateLernSessionInteractionCount(){
        try {

            if (lernSession) {

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: context.actions.authHeader(),
                    },
                };
                const putData = {
                    lernsession_id: lernSession.lernSessionId,
                    interaction_count: lernSession.interactionCount,
                };

                axios.put(AppSettings.UPDATE_LERNSESSION_INTERACTION_COUNT, putData, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("response: ", response);
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating interaction count lernsession:", error);
                        // Appropriately handle the error
                        console.log(error.message);
                        console.log(error.request);
                        console.log(error.config);
                    });
            }
        } catch (error) {
            console.error("interaction count couldn't be updated", error);
        }
    }

    useEffect(() => {

        // Handle click events
        const handleClick = (event: MouseEvent) => {
            if (event.composedPath().some(el => el instanceof HTMLElement && el.className === 'input-actions-container')) {
                console.log("send button in melibo clicked");
                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;

                //setInteractionCount(prevCount => prevCount + 1);
                console.log(`Interaction count: ${lernSession.interactionCount}`);

            }

            if (event.composedPath().some(el => el instanceof HTMLElement && el.id === 'WACInputContainer__SendButton')) {
                console.log("send button in Watson clicked");

                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;
                console.log(`Interaction count: ${lernSession.interactionCount}`);

                //setInteractionCount(prevCount => prevCount + 1);
                //console.log(`Interaction count: ${interactionCount + 1}`);

            }


        };

        const handleAnswerClick = (event: MouseEvent) => {
            if (event.composedPath().some(el => el instanceof HTMLElement && el.className === 'button')) {
                console.log("Click inside Melibo");

                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;
                console.log(`Interaction count: ${lernSession.interactionCount}`);


                //setInteractionCount(prevCount => prevCount + 1);
                //console.log(`Interaction count: ${interactionCount + 1}`);

            }

            if (event.composedPath().some(el => el instanceof HTMLElement && el.className === 'WAC__button-holder')) {
                console.log("Click inside Watson");

                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;
                console.log(`Interaction count: ${lernSession.interactionCount}`);

                //setInteractionCount(prevCount => prevCount + 1);
                //console.log(`Interaction count: ${interactionCount + 1}`);

            }

        };

        // Handle keydown events
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && event.composedPath().some(el => el instanceof HTMLElement && el.className === 'melibo_input_with_helper')) {
                console.log("Enter key pressed inside Melibo webchat");

                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;
                console.log(`Interaction count: ${lernSession.interactionCount}`);

                //setInteractionCount(prevCount => prevCount + 1);
                //console.log(`Interaction count: ${interactionCount + 1}`);
            }

            if (event.key === 'Enter' && event.composedPath().some(el => el instanceof HTMLElement && el.className === 'WACInputContainer__TextAndUpload')) {
                console.log("Enter key pressed inside Watson webchat");

                // @ts-ignore
                lernSession.interactionCount = lernSession.interactionCount +1;
                console.log(`Interaction count: ${lernSession.interactionCount}`);

                //setInteractionCount(prevCount => prevCount + 1);
                //console.log(`Interaction count: ${interactionCount + 1}`);
            }
        };

        // Add event listeners to the document
        document.addEventListener('click', handleClick);
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleAnswerClick);


        return () => {
            // Remove event listeners when the component unmounts
            document.removeEventListener('click', handleClick);
            document.removeEventListener('keydown', handleKeyDown);
            document.addEventListener('click', handleAnswerClick);

        };
    }, []);

    /*useEffect(() => {
        // Function to reset interaction count to 0 when the lernsessionId changes
        const resetInteractionCount = () => {
            // Reset the interaction count to 0
            updateLernSessionInteractionCount();
            console.log("actual interaction count", lernSession.interactionCount);

            lernSession.interactionCount = 0;

            //setInteractionCount(0);
            console.log('Resetting interaction count to 0 for new learning session:', lernSession.lernSessionId);
        };

        if(!location.pathname.includes("/abschlusstest/") && !location.pathname.includes("/befragter/")) {
            // Reset the interaction count when the lernsessionId changes
            resetInteractionCount();
        }

        if(location.pathname.includes("/lernmethode/")) {
            // Reset the interaction count when the lernsessionId changes
            resetInteractionCount();
        }

        // Cleanup function
        return () => {
            // No cleanup needed
        };
    }, [location]);*/

    useEffect(() => {
        const resetInteractionCount = () => {
            // Reset the interaction count to 0
            updateLernSessionInteractionCount();
            console.log("actual interaction count", lernSession.interactionCount);

            lernSession.interactionCount = 0;

            //setInteractionCount(0);
            console.log('Resetting interaction count to 0 for new learning session:', lernSession.lernSessionId);
        };

        return history.listen((location) => {
            console.log("actual path", location.pathname);
            if (!location.pathname.includes(`/abschlusstest/`)) {
                resetInteractionCount();
            }
        });
    }, [history, lernSession.lernSessionId]);

    useEffect(() => {
        // Function to reset interaction count to 0 when the lernsessionId changes
        const resetInteractionCount = () => {
            // Reset the interaction count to 0
            lernSession.interactionCount = 0;


            //setInteractionCount(0);
            console.log('Resetting interaction count to 0 for new learning session:', lernSession.lernSessionId);
        };


        // Event listener for beforeunload
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (confirmUnload) {

                updateLernSessionInteractionCount();

                resetInteractionCount();

                return;
            }

            // Prompt the user before leaving the page
            const confirmationMessage = 'Are you sure you want to leave? Your progress may not be saved.';
            event.returnValue = confirmationMessage; // Standard for most browsers
            return confirmationMessage; // Required for Safari
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [confirmUnload]);


    console.log(`Interaction count: ${lernSession.interactionCount}`);

    return null;  // This component does not render any visible content
}

export default ChatInputMonitor;
