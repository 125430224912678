import React, {createContext, useState, useContext, useCallback, useEffect} from "react";
import axios from "axios";
import { AppSettings } from "../config"; // Make sure this path is correct
import {userContext} from "./UserContext";
import {CreateUserData, LoginOptions, PersonalizationOptions} from "../models/User";



type LernSessionContext = {
    lernSessionId: number | null,
    interactionCount: number | null,
    actions: {
        createLernSession: (trainingOption: number, category_id: string) => any;
        getLernSessionId: () => number | null;
        getInteractionCount: () => number | null;
        updateInteractionCount: (newInteractionCount: number | null) => void;
    };
}

export const initialContext = {
    lernSessionId: null,
    interactionCount: null,
    actions: {
        createLernSession: (trainingOption: number, category_id: string) => {},
        getLernSessionId: () => {
            return null;
        },
        getInteractionCount: () => {
            return null;
        },
        updateInteractionCount: (newInteractionCount: number | null) => {
            return null;
        },
    },
}

export const lernSessionContext = React.createContext<LernSessionContext>(initialContext);

export const LernSessionProvider: React.FC = ({ children }) => {
    const context = useContext(userContext)
    const [lernSessionId, setLernSessionId] = useState<number | null>(null);
    const [interactionCount, setInteractionCount] = useState<number | null>(null);

    console.log("this happens after logging in" + context.user?.user_id);

    useEffect(() => {
        async function createLernSession(){

        }
    }, [context.user]);

    function getLernSessionId() {
        return lernSessionId;
    }

    function getInteractionCount() {
        return interactionCount;
    }

    function updateInteractionCount(newInteractionCount: number | null) {
        return setInteractionCount(newInteractionCount);
    }

    async function createLernSession(trainingOption: number, category_id: string): Promise<string | null> {
        if (!context.user) {
            console.error("User context is not set.");
            return null;  // Ensure a return here if user context is missing
        }

        const postData = {
            user_id: context.user.user_id,
            lernmethode: trainingOption,
            category_id: Number(category_id),
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: context.actions.authHeader(),
            },
        };

        try {
            const response = await axios.post(AppSettings.CREATE_LERNSESSION, postData, config);
            if (response.status === 200 && response.data.data && response.data.data.lernsession_id) {
                setLernSessionId(response.data.data.lernsession_id);
                setInteractionCount(0);
                return response.data.data.lernsession_id; // Return the session ID
            } else {
                console.error("Failed to get valid session ID from response");
                return null; // Return null if no session ID is found in the response
            }
        } catch (error) {
            console.error("Error creating lernsession:", error);
            return null; // Return null if an error occurs
        }
    }


    return (
        <lernSessionContext.Provider
            value={{
                lernSessionId: lernSessionId, interactionCount,
                actions: {createLernSession, getLernSessionId, getInteractionCount, updateInteractionCount}
            }}
        >
            {children}
        </lernSessionContext.Provider>
    );
};

