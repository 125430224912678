import {useEffect, useContext, useState} from "react";
import {Button, Container, Tooltip} from "react-bootstrap";
import ChatInputMonitor from '../components/ChatInputMonitor';

import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import "../styles.css";
import {AppSettings} from "../config";
import { userContext } from "../contexts/UserContext";
import { lernSessionContext } from "../contexts/LernSessionContext";

import { UserStatus } from "../models/User";
import * as React from 'react';
import {InterviewBackground} from "../components/InterviewBackground";
import axios from "axios";
import chatInputMonitor from "../components/ChatInputMonitor";

type TParams = { id: string, lernsessionId: string};

export const InterviewedPage = ({ match }: RouteComponentProps<TParams>) => {
  const [category_id] = useState(match.params.id);

  const history = useHistory();
  const location = useLocation();

  const context = useContext(userContext);
  const lernSession = useContext(lernSessionContext);
  const [userStatus] = useState<UserStatus[]>(context.status);
  let categoryStatus: string = "";

  userStatus.forEach((entry) => {
    if (Number(category_id) === entry.category.category_id) {
      categoryStatus = entry.status;
    }
  });

  const renderMeliboChatbot = () => {
    return React.createElement(
        "melibo-webchat",
        {
          'api-key': "T486E34-EQAAV2T-SAI2A480-TACAR02",
          'environment-id': "624c2046250b8745a45e31b3",
        }
    );
  }

    function updateLernSessionFinishedAt(){
        try {
            if (lernSession) {

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: context.actions.authHeader(),
                    },
                };

                axios.put(AppSettings.UPDATE_LERNSESSION_FINISHEDAT + lernSession.lernSessionId, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("response: ", response);
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating lernsession:", error);
                        // Appropriately handle the error
                        console.log(error.message);
                        console.log(error.request);
                        console.log(error.config);
                    });
            }
        } catch (error) {
            console.error("abschlusstest_geschrieben couldn't be updated", error);
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            console.log("actual path", location.pathname);
            if (location.pathname !== `/abschlusstest/${category_id}`) {
                updateLernSessionFinishedAt();
            }
        });
    }, [history]);


    return (
      <>
          <ChatInputMonitor />
        <InterviewBackground title={"Multiple-Choice Quiz"}
                             textOfFirstRow={"Indem Du auf das Icon unten rechts klickst, " +
                                 "gelangst Du zum Chatbot mit dem Du die Trainingseinheit " +
                                 "für das gewählte Fachthema absolvieren kannst."}
                             textOfSecondRow={"Um das Quiz zu starten, tippe das Fachthema " +
                                 "in Verbindung mit Abfrage starten ein. Zum Beispiel: \"Malware Abfrage starten.\""}
                             textOfThirdRow={"Im nächsten Schritt werden Dir Fragen vom Chatbot gestellt, " +
                                 "die es zu beantworten gilt."}
                             textOfFourthRow={"Du hast deine Trainingseinheiten erfolgreich absolviert " +
                                 "und möchtest dein Wissen unter Beweis stellen? " +
                                 "Dann solltest Du den Abschlusstest antreten, damit dein Wissen gemessen werden kann."}
                             category_id={category_id}
                             categoryStatus={categoryStatus}
                             icon_path={"/images/befragter/melibo_icon.png"} />

        {
          renderMeliboChatbot()
        }
      </>
  );
};