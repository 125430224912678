import React, {useState, useEffect, useRef, useContext} from 'react';
import axios from 'axios';
import {userContext} from "../../contexts/UserContext";
//import {AppSettings} from "../../config";

interface Message {
    text: string;
    fromUser: boolean;
    isWelcome?: boolean;
    userid: number | undefined;
}

const ChatWindow = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const context = useContext(userContext);

    useEffect(() => {
        const loadedMessages = JSON.parse(sessionStorage.getItem('chat_messages') || '[]') as Message[];
        if (loadedMessages.length === 0) {
            loadedMessages.push({
                text: "Willkommen beim PyTorch Chatbot. Bitte stellen Sie mir Fragen zum ausgewählten IT-Sicherheitsthema.",
                fromUser: false,
                isWelcome: true,
                userid: context.user?.user_id
            });
        }
        setMessages(loadedMessages);
    }, []);

    useEffect(() => {
        localStorage.setItem('chat_messages', JSON.stringify(messages));
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);


    const sendMessage = async () => {
        const question = input.trim();
        if (!question) {
            return;
        }
        const postData = {
            user_id: context.user?.user_id,
            question: input.trim()
        };

        const newMessage: Message = { text: question, fromUser: true, userid: context.user?.user_id };
        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);
        sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessages));
        setInput('');

        //const path_to_chatbot_local = 'http://localhost:5000/chat'                        //for local tests only
        const path_to_chatbot_server = 'https://chatbot.ai.test-securebot.users.h-da.cloud:443/chat'
        //const path_to_chatbot: string = AppSettings.REACT_APP_CHATBOT_URL_WITH_ROUTE      //did not work
        try {
            const response = await axios.post(path_to_chatbot_server, { postData });
            if (response.data) {
                const answer: Message = { text: response.data.answer, fromUser: false, userid: context.user?.user_id };
                const updatedMessagesWithAnswer = [...updatedMessages, answer];
                setMessages(updatedMessagesWithAnswer);
                sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessagesWithAnswer));
            } else {
                const errorMessage : Message = {
                    text: "Currently not able to reach the backend",
                    fromUser: false,
                    userid: context.user?.user_id
                }
                const updateMessagesWithErrorMessage = [...updatedMessages, errorMessage]
                setMessages(updateMessagesWithErrorMessage);
                sessionStorage.setItem('chat_messages', JSON.stringify(updateMessagesWithErrorMessage));
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // Function to process messages and replace HTML tags with line breaks
    const processMessages = (newMessages: Message[]) => {
        const processedMessages = newMessages.map(message => {
            // Replace <br><br> with line breaks
            const processedText = message.text.replace(/<br\s*\/?>/g, '\n');
            return { ...message, text: processedText };
        });
        return processedMessages;
    };

    return (
        <div style={{ position: 'fixed', bottom: 24, left: 24, width: '380px', transition: 'all 0.3s ease' }}>
            {isOpen && (
                <div style={{
                    height: '640px',
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        padding: '10px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <span style={{width: '100%', textAlign: 'center'}}>PyTorch Chatbot</span>
                        <button onClick={() => setIsOpen(!isOpen)} style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: 'large'
                        }}>
                            −
                        </button>
                    </div>
                    <div style={{overflowY: 'auto', flexGrow: 1}}>
                        {messages.map((msg, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                justifyContent: msg.fromUser ? 'flex-end' : 'flex-start',
                                marginBottom: '10px',
                                marginLeft: '5px',
                                marginRight: '5px' 
                            }}>
                                <div style={{
                                    backgroundColor: msg.fromUser ? '#007bff' : '#e2e2e2',
                                    color: msg.fromUser ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '20px',
                                    maxWidth: '80%',
                                    wordWrap: 'break-word',
                                    textAlign: msg.fromUser ? 'right' : 'left',
                                }}>
                                
                                <div dangerouslySetInnerHTML={{ __html: msg.text }} style={{fontSize: '13px'}} />   
{/*                                 {msg.text.split('<br>').map((line, idx) => (
                                    <p key={idx} style={{
                                        margin: 0,
                                        fontSize: '14px'
                                    }}>{line}</p>
                                ))} */}
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef}/>
                    </div>
                    <div style={{display: 'flex', padding: '10px 0'}}>
                        <input 
                            type="text" value={input} onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && sendMessage()} 
                            placeholder="Hier eingeben..."
                            style={{
                                flexGrow: 1,
                                padding: '10px',
                                borderRadius: '20px',
                                border: '1px solid #ccc',
                                marginRight: '10px',
                                marginLeft: 5
                            }}
                        />
                        <button onClick={sendMessage} 
                            style={{
                                padding: '10px 20px',
                                borderRadius: '20px',
                                border: 'none',
                                backgroundColor: '#007bff',
                                marginRight: 5,
                                color: 'white',
                                cursor: 'pointer'
                            }}>
                            Send
                        </button>
                    </div>
                </div>
            )}
            {!isOpen && (
                <button onClick={() => setIsOpen(!isOpen)} style={{
                    width: 56,
                    height: 56,
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer'
                }}>
                    <img
                        src="/images/chatbot/robot_chat_512_512.png" // Relative path to your image
                        alt="Chatbot"
                        style={{ 
                            width: '75%', 
                            height: '75%',
                            filter: 'brightness(10) invert(1) sepia(1) saturate(10000%) hue-rotate(180deg)'
                        }}
                    />
                </button>
            )}
        </div>
    );
    
};

export default ChatWindow;
 

