import React, {useContext, useState} from "react";
import {Button, ButtonGroup, Form, InputGroup, Modal, OverlayTrigger, ToggleButton, Tooltip} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {Eye, InfoCircle} from "react-bootstrap-icons";
import axios from "axios";
import {AppSettings} from "../config";
import {userContext} from "../contexts/UserContext";
import {CreateUserData} from "../models/User";
import "../register.css";
import {PrivacyPolicyText} from "../components/PrivacyPolicyText";

export const Signup: React.FC = () => {
	const context = useContext(userContext);
	const history = useHistory();

	const [username, setUsername] = useState("");
	const [errorMsgUsername, setErrorMsgUsername] = useState("");
	const [email, setEmail] = useState("");
	const [fieldOfStudy, setFieldOfStudy] = useState("");
	const [errorMsgEmail, setErrorMsgEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMsgPw, setErrorMsgPw] = useState("");
	const [passwordShow, setPasswordShow] = useState(false);
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [errorMsgPwConfirmation, setErrorMsgPwConfirmation] = useState("");
	const [passwordConfirmationShow, setPasswordConfirmationShow] = useState(false);
	const [age, setAge] = useState("");
	const [errorMsgAge, setErrorMsgAge] = useState("");
	const [genderId, setGenderId] = useState(0);
	const [errorMsgGenderId, setErrorMsgGenderId] = useState("");
	const [secretKey, setSecretKey] = useState("");
	const [errorMsgSecretKey, setErrorMsgSecretKey] = useState("");
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [errorMsgPrivacyPolicy, setErrorMsgPrivacyPolicy] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [errorMsgStudy, setErrorMsgStudy] = useState("");


	const [isLoading, setLoading] = useState(false);
	const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);

	type gender = {
		name: string;
		value: number;
	};

	const genderRadios: Array<gender> = [
		{ name: "Weiblich", value: 1 },
		{ name: "Männlich", value: 2 },
		{ name: "Divers", value: 3 },
	];

	const handleRegister = async (event: any, userData: CreateUserData) => {
		const nickname = username;

		event.preventDefault();
		setLoading(true);

		await checkInputFields();

		if (await checkUsername()
			&& checkEmailPattern()
			&& checkPasswordPattern()
			&& checkPasswordEqual()
			&& checkAge()
			&& checkGenderId()
			&& await checkSecretKey()
			&& checkPrivacyPolicy()
			&& checkFieldOfStudy())
		{
			context.actions.register(userData)
				.then(async (response: any) => {
					setLoading(false);

					if (response.status === 200) {
						await context.actions.login({ nickname, password });
						clearStates();
						history.push("/personalisierung");
					} else {
						setErrorMsgUsername(response.data?.error);
					}
				})
				.catch((error : any) => {
                    console.error("Error at SignUp:", error);
					if (error.response === undefined) {
						console.log("Response is undefined");
					}
					console.log(error.response?.data);
					console.log(error.response?.status);
					console.log(error.response?.headers);
                });
		} else {
			setErrorMsg("Es müssen alle Pflichtfelder ausgefüllt werden.");
		}
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	const checkInputFields = async () => {
		await checkUsername();
		checkEmailPattern();
		checkPasswordPattern();
		checkPasswordEqual();
		checkAge();
		checkGenderId();
		await checkSecretKey();
		checkPrivacyPolicy();
		checkFieldOfStudy();
	};


	const checkUsername = async () => {
		// ^ = Beginn eines Strings
		// [a-zßåäöüA-ZÅÄÖÜ]{4,} = mind. 4 oder mehr Buchstaben
		// \d* = keine oder beliebig viele Ziffern
		// [a-zßåäöüA-ZÅÄÖÜ\d]* = keine oder beliebig viele Buchstaben/Ziffern
		// ([.\-_][a-zßåäöüA-ZÅÄÖÜ\d]+)* = GENAU ein Punkt/Bindestrich/Unterstrich
		// und danach mindestens einen oder mehr Buchstaben/Ziffern
		// -> letzten zwei Zeilen kann 0x oder beliebig oft sein
		// $ = Ende eines Strings
		const usernamePattern = /^[a-zßåäöüA-ZÅÄÖÜ]{4,}\d*[a-zßåäöüA-ZÅÄÖÜ\d]*([.\-_][a-zßåäöüA-ZÅÄÖÜ\d]+)*$/;
		let usernameAvailable = false;

		if (username.length === 0) {
			setErrorMsgUsername("Es muss ein Benutzername eingegeben werden.");
			usernameAvailable = false;
		}
		else if (username.length < 4
			|| username.length > 15
			|| !usernamePattern.test(username)) { // testen, ob username NICHT dem Pattern entspricht
			setErrorMsgUsername("Der Benutzername muss 4 bis 15 Zeichen lang sein. Weitere Hinweise siehe Info.");
			usernameAvailable = false;
		}
		else {
			await axios.post(AppSettings.CHECK_USERNAME_AVAILABILITY_URL, {
				nickname: username,
			}).then((response) => {
				if (response.status === 200 && response.data.usernameAvailable === true) {
					setErrorMsgUsername("");
					usernameAvailable = true;
				} else {
					setErrorMsgUsername("Dieser Benutzername ist bereits vorhanden.");
					usernameAvailable = false;
				}
			});
		}

		return usernameAvailable;
	};

	const checkEmailPattern = () => {

		 const mailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
		
		 if (email.length > 0) { // testen, ob eine Email angegeben wurde
		 	if (mailPattern.test(email)) { // testen, ob Email genau dem Pattern entspricht
		 		setErrorMsgEmail("");
		 		return true;
		 	} else {
		 		setErrorMsgEmail("Es muss eine gültige E-Mail Adresse eingegeben werden.");
		 		return false;
		 	}
		 } else { // User will keine Email angeben also true
		 	setErrorMsgEmail("");
		 	return true;
		 }
	};

	const checkFieldOfStudy = () =>{
		if(fieldOfStudy.length == 0){
			setErrorMsgStudy("Es muss eine Berufsbezeichnung oder ein Studiengang angegeben werden");
			return false;
		}else {
			setErrorMsgStudy("");
			return true;
		}
	};

	const checkPasswordPattern = () => {
		// Sonderzeichen als Regulärer Ausdruck (regular expression)
		const specialCharacters = /[?!@#&$€%^*~_+\-=:;,.´`'"|\\/<>{}()\[\]]/;

		if (password.length == 0) {
			setErrorMsgPw("Es muss ein Passwort eingegeben werden.");
			return false;
		}
		else if (!/\s/.test(password) // testen, ob ein Leerzeichen enthalten ist, wenn nicht dann true
			&& password.length >= 8 // Passwort-Länge mind 8 Zeichen
			&& /\d/.test(password) // testen, ob mind. 1 Zahl enthalten ist
			&& /[a-zåäöüß]/.test(password) // testen, ob ein Kleinbuchstabe oder åäöü ß enthalten ist
			&& /[A-ZÅÄÖÜ]/.test(password) // testen, ob ein Großbuchstabe oder ÅÄÖÜ enthalten ist
			&& specialCharacters.test(password) // testen, ob ein Sonderzeichen enthalten ist
		) {
			setErrorMsgPw("");
			return true;
		} else {
			setErrorMsgPw("Das Passwort muss mindestens 8 Zeichen lang sein und mindestens " +
				"einen Groß- und Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.");
			return false;
		}
	};

	const checkPasswordEqual = () => {
		if (password !== passwordConfirmation) {
			setErrorMsgPwConfirmation("Die Passwörter stimmen nicht überein.");
			return false;
		} else {
			setErrorMsgPwConfirmation("");
			return true;
		}
	};

	const checkAge = () => {
		if (age === "") {
			setErrorMsgAge("Es muss ein Alter angegeben werden.");
			return false;
		}
		else if (parseInt(age) < 18 || parseInt(age) > 110) {
			setErrorMsgAge("Es muss ein echtes Alter angegeben werden und du musst mindestens 18 Jahre alt sein.");
			return false;
		} else {
			setErrorMsgAge("");
			return true;
		}
	};

	const checkGenderId = () => {
		if (genderId === 0) {
			setErrorMsgGenderId("Es muss ein Geschlecht ausgewählt werden.");
			return false;
		} else {
			setErrorMsgGenderId("");
			return true;
		}
	};

	const checkSecretKey = async () => {
		let secretKeyValid = false;

		if (secretKey.length == 0) {
			setErrorMsgSecretKey("Es muss ein Registrierungsschlüssel eingegeben werden.");
			secretKeyValid = false;
		} else {
			await axios.post(AppSettings.CHECK_SECRET_KEY_URL, {
				secretKey: secretKey,
			}).then((response) => {
				if (response.status === 200 && response.data.secretKeyValid === true) {
					setErrorMsgSecretKey("");
					secretKeyValid = true;
				} else {
					setErrorMsgSecretKey("Der Registrierungsschlüssel ist nicht korrekt.");
					secretKeyValid = false;
				}
			});
		}

		return secretKeyValid;
	};

	const checkPrivacyPolicy = () => {
		if (privacyPolicy) {
			setErrorMsgPrivacyPolicy("");
			return true;
		} else {
			setErrorMsgPrivacyPolicy("Zum Registrieren muss der Datenschutzerklärung zugestimmt werden.");
			return false;
		}
	};

	const clearStates = () => {
		setUsername("");
		setErrorMsgUsername("");
		setEmail("");
		setErrorMsgEmail("");
		setPassword("");
		setErrorMsgPw("");
		setPasswordShow(false);
		setPasswordConfirmation("");
		setErrorMsgPwConfirmation("");
		setPasswordConfirmationShow(false);
		setAge("");
		setFieldOfStudy("");
		setErrorMsgAge("");
		setGenderId(0);
		setErrorMsgGenderId("");
		setSecretKey("");
		setErrorMsgSecretKey("");
		setPrivacyPolicy(false);
		setErrorMsgPrivacyPolicy("");
		setErrorMsg("");
		setErrorMsgStudy("");

		setLoading(false);
	};

	return (
		<div
			className="d-flex align-items-center"
		>
			<div
				className="container align-items-center register-container box-blur"
				style={{ marginTop: "40px", marginBottom: "40px" }}
			>
				<Form style={{ padding: "20px" }}>
					<h3 className="text-center">Account erstellen</h3>

					<Form.Group>
						<Form.Label className="font-weight-bold">
							Benutzername (Pseudonym)*

							<span> </span>

							<OverlayTrigger
								placement="bottom"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="button-tooltip">
										Der Benutzername muss 4 bis 15 Zeichen lang sein.
										Er muss mit 4 Buchstaben anfangen.
										Er darf danach nur Buchstaben und Ziffern
										und dazwischen ein Punkt, Binde- oder Unterstrich enthalten.
									</Tooltip>
								}
							>
								<InfoCircle />
							</OverlayTrigger>
						</Form.Label>
						<Form.Control
							type="name"
							name="username"
							placeholder="Benutzername eingeben"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgUsername}</Form.Text>
					</Form.Group>

					<Form.Group controlId="formBasicEmail">
						<Form.Label className="font-weight-bold">E-Mail Adresse (Optional)</Form.Label>
						<Form.Control
							type="email"
							name="email"
							placeholder="Email-Adresse eingeben"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgEmail}</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label className="font-weight-bold">Passwort*</Form.Label>
						<InputGroup>
							<Form.Control
								type={passwordShow ? "text" : "password"}
								name="password"
								placeholder="Passwort eingeben"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputGroup.Prepend
								className="showPasswordIcon"
								onClick={() =>
									!passwordShow && password.length !== 0
										? setPasswordShow(true)
										: setPasswordShow(false)
								}
							>
								<InputGroup.Text>
									<Eye/>
								</InputGroup.Text>
							</InputGroup.Prepend>
						</InputGroup>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgPw}</Form.Text>

						<Form.Label className="font-weight-bold">Passwort bestätigen*</Form.Label>
						<InputGroup>
							<Form.Control
								type={passwordConfirmationShow ? "text" : "password"}
								name="passwordConfirmation"
								placeholder="Passwort bestätigen"
								value={passwordConfirmation}
								onChange={(e) => setPasswordConfirmation(e.target.value)}
							/>
							<InputGroup.Prepend
								className="showPasswordIcon"
								onClick={() =>
									!passwordConfirmationShow && passwordConfirmation.length !== 0
										? setPasswordConfirmationShow(true)
										: setPasswordConfirmationShow(false)
								}
							>
								<InputGroup.Text>
									<Eye/>
								</InputGroup.Text>
							</InputGroup.Prepend>
						</InputGroup>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgPwConfirmation}</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Label className="font-weight-bold">Berufsbezeichnung*
							<span> </span>

							<OverlayTrigger
								placement="bottom"
								delay={{show: 250, hide: 400}}
								overlay={
									<Tooltip id="button-tooltip">
										Wenn du noch studierst, kannst du dein Studienfach angeben.
									</Tooltip>
								}
							>
								<InfoCircle/>
							</OverlayTrigger>
						</Form.Label>
						<Form.Control
							type="string"
							name="fieldOfStudy"
							placeholder="Berufsbezeichnung/Studiengang eingeben"
							value={fieldOfStudy}
							onChange={(e: any) => setFieldOfStudy(e.currentTarget.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgStudy}</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label className="font-weight-bold">Alter*</Form.Label>
						<Form.Control
							type="number"
							name="age"
							placeholder="Alter eingeben"
							min="18"
							max="110"
							value={age}
							onChange={(e: any) => setAge(e.currentTarget.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgAge}</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label className="font-weight-bold">Geschlecht*</Form.Label>
						<ButtonGroup className="genderRadioBox " toggle>
							{genderRadios.map((radio, idx) => (
								<ToggleButton
									key={idx}
									type="radio"
									name="genderId"
									variant="outline-primary"
									value={radio.value}
									checked={genderId === radio.value}
									onChange={(e) => {
										setErrorMsgGenderId("");
										setGenderId(Number(e.currentTarget.value));
									}}
								>
									{radio.name}
								</ToggleButton>
							))}
						</ButtonGroup>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgGenderId}</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label className="font-weight-bold">Registrierungsschlüssel*</Form.Label>
						<Form.Control
							type="name"
							name="secretKey"
							placeholder="Registrierungsschlüssel eingeben"
							value={secretKey}
							onChange={(e) => setSecretKey(e.target.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgSecretKey}</Form.Text>
					</Form.Group>

					<Form.Group>
						<input
							type="checkbox"
							name="privacyPolicy"
							onChange={(e) => setPrivacyPolicy(e.target.checked)}
						/>
						<span id="dataprivacy" className="text-dark">
							{" "}Ich habe die{" "}
							<span className="privacyPolicyText" onClick={() => setPrivacyModalIsOpen(true)}>
								Datenschutzerklärung
							</span>
							{" "}dieser Website gelesen und stimme dieser zu.
						</span>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgPrivacyPolicy}</Form.Text>
					</Form.Group>

					<p className="requiredFields">* Pflichtfelder</p>

					<Form.Text className="text-danger font-weight-bold">{errorMsg}</Form.Text>

					<Button
						className="mb-2 mt-2"
						variant="info"
						size="lg"
						style={{backgroundColor: "#007bff"}}
						block
						onClick={(e: any) =>
							handleRegister(e, {
								nickname: username,
								password: password,
								e_mail: email,
								age: Number(age),
								gender_id: genderId,
								fieldOfStudy: fieldOfStudy,
								regkey:secretKey,
							})
						}
					>
						Registrieren
						{
							isLoading
							? (<span
									className="spinner-border spinner-border-sm ml-5"
									role="status"
									aria-hidden="true"
								/>)
							: (<span/>)
						}
					</Button>

					<Link to="/login" className="text-blue text-center">
						Du bist bereits registriert? Zum Login.
					</Link>
				</Form>
			</div>

			<footer	className="text-muted bg-dark variant-dark footer">
				<div
					className="container d-flex justify-content-between"
					style={{ paddingBottom: "10px", paddingTop: "10px" }}
				>
					<div>
						<a href="https://fbi.h-da.de/">Hochschule Darmstadt Fachbereich Informatik</a>
					</div>
					<div className="privacyPolicyText" onClick={() => setPrivacyModalIsOpen(true)}>
						Datenschutzerklärung
					</div>
					<div>
						<a href="/#">Zum Seitenanfang</a>
					</div>
					<div>
						<a href="mailto:securebot-contact.fbi@h-da.de"> Kontaktiere uns</a>
					</div>
				</div>
			</footer>

			<Modal
				size="lg"
				show={privacyModalIsOpen}
				onHide={() => setPrivacyModalIsOpen(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title>
						Datenschutzerklärung
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrivacyPolicyText />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setPrivacyModalIsOpen(false)}>
						Schließen
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
